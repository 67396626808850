@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-bottom: 60px;

  .author {
    margin-bottom: 20px;
  }

  .authorNameWrapper {
    margin-bottom: 32px;
  }

  .authorName {
    margin-bottom: 16px;
    text-align: center;
  }

  .socialLink {
    display: block;
    margin-bottom: 5px;
    font-size: 19px;
    text-align: center;
  }

  .authorJob {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .authorImage {
    width: 100%;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    img {
      object-fit: cover;
    }
  }

  .authorExperience {
    img {
      max-width: 50%;
    }
  }

  .row {
    display: flex;
    gap: 30px;
  }

  .col {
    width: 100%;

    &.small {
      width: 333px;
      flex-shrink: 0;
    }
  }

  @include small-break {
    margin-top: 30px;

    .row {
      flex-wrap: wrap;
    }

    .col {
      &.small {
        width: 100%;
      }
    }
  }
}
