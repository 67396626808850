@use '@/styles/config/_mixins.scss' as *;

.sectionWrapper {
  position: relative;

  .backgroundImage {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 167px;
  }

  @include extra-small-break {
    .backgroundImage {
      top: 7.5%;
      bottom: 7.5%;
      height: 85%;
      background-size: unset;
      background-repeat: repeat-y;
    }
  }
}
