@use '@/styles/config/_mixins.scss' as *;

.videoWrapper {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 37px;
  margin-left: auto;
  margin-right: auto;

  @supports not (aspect-ratio: 574 / 388) {
    padding-top: 67.6%;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .posterContainer {
    position: absolute;
    background-color: transparent;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
  }

  .poster {
    position: relative;
    height: 100%;
    cursor: pointer;

    img {
      position: absolute;
      margin: 0 !important;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .videoContainer {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    background-color: #000000;

    iframe {
      position: absolute;
      margin: 0;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .playIconWrapper {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    cursor: pointer;

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include mid-break {
    margin-top: -15px;
    margin-bottom: 22px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include extra-small-break {
    .playIconWrapper {
      width: 39px;
      height: 39px;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
