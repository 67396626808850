@use '@/styles/config/_mixins.scss' as *;

.link {
  background: #2852a1;
  color: #fff;
  border-radius: 3px;
  font-weight: 700;
  letter-spacing: .01rem;
  text-decoration: none;
  opacity: 1;
  transition: opacity ease 0.3s;

  &.extra-small {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 153px;
    min-height: 45px;
    padding: 5px;
    font-size: 14px;
  }

  &.extra-large {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 305px;
    max-width: 100%;
    padding: 25px;
    border-radius: 5px;
    font-size: 19px;
  }

  &.big {
    padding: 15px 46px;
    font-size: 24px;
  }

  &.medium {
    padding: 17px 42px;
    font-size: 20px;
    line-height: 0.9;
  }

  &.small {
    padding: 17px 38px;
    font-size: 18px;
    line-height: 1;
  }

  &.yellow {
    background: #efe711;
    border-bottom: 2px solid #bcb60e;
    color: #000;
  }

  @include mouseHover {
    opacity: 0.7;
  }

  @include extra-small-break {
    &.extra-small {
      width: 121px;
      min-height: 36px;
      font-size: 14px;
    }
  }
}