@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-bottom: 30px;

  h1 {
    margin-bottom: 20px;
    text-align: center;
  }

  .accordionHeading {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    box-shadow: none;
    border-radius: 0;
    color: #1D77B8;
    margin-bottom: 0;
    border: 1px solid #D0D5DD;
    padding: 15px 40px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 1.444;
    letter-spacing: normal;

    .accordionHeadingText {
      color: #0A0A14;
    }

    .opened {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      transform: scaleY(1);
    }

    &:global(.collapsible__toggler--is-open) {
      .opened {
        display: inline;
      }

      .closed {
        display: none;
      }

      svg {
        transform: scaleY(-1);
      }
    }
  }

  .accordionDescription {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 15px 58px;
  }

  .container {
    position: relative;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  .accordionHeading {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    text-align: left;
    list-style: none;
    margin-top: 9px;

    &:first-child {
      margin-top: 0;
    }
  }

  .accordionDescription {
    background: var(--description-bg, #F7F6F7);
    color: #0A0A14;
    text-align: left;
    list-style: none;
    font-size: 16px;
    line-height: 1.5;
  }

  @include small-break {
    margin-top: 25px;
    margin-bottom: 25px;

    .accordionHeading {
      position: relative;
      padding: 15px 40px;
      padding-left: 78px;

      &::before, &::after {
        content: "";
        position: absolute;
        left: 40px;
        top: 50%;
        width: 18px;
        height: 4px;
        background-color: #1D77B8;
        transform: translateY(-50%);
        transition: transform 0.3s ease;
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }

      &:global(.collapsible__toggler--is-open) {
        &::after {
          transform: translateY(-50%);
        }
      }
    }

    .accordionDescription {
      padding: 17px 50px;
    }

    .desktop {
      display: none;
    }

    .container {
      max-width: 770px;
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  @include extra-small-break {
    .accordionHeading {
      padding: 20px 30px;
      padding-left: 68px;
      border: 1px solid #C0C4C5;

      &:before, &::after {
        left: 30px;
      }
    }

    .accordionDescription {
      padding: 30px 38px;
      border: 1px solid #C0C4C5;
    }
  }
}