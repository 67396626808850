.placeholder {
  background-color: var(--theme-elevation-50);
}

[data-theme=dark] {
  .hasDarkModeFallback.themeLight {
    display: none;
  }
}

[data-theme=light] {
  .themeDark {
    display: none;
  }
}