@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-top: auto;
  padding-top: 26px;
  padding-bottom: calc(24px + var(--sticky-button, 0));
  background-color: #262830;
  color: white;

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    padding: 0 10px;
  }

  .col {
    &.big {
      max-width: 65%;
    }

    &.small {
      max-width: calc(35% - 24px);
    }
  }

  .logo {
    margin-bottom: 10px;
  }

  .disclaimers {
    max-width: 482px;
    margin-top: 6px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 1.45;
    letter-spacing: 0.2px;
    color: #FFFFFF;
  }

  .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    margin-bottom: 4px;
  }

  .linksWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;
    gap: 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    li {
      font-family: 'Mulish';
      font-weight: 400;
      font-size: 0.6875rem;
      line-height: 1.45;
      letter-spacing: 0.2px;
      color: #FFFFFF;

      &:not(:first-child) {
        position: relative;

        &::before {
          content: "|";
          position: absolute;
          left: -11px;
          bottom: 1px;
        }
      }

      a {
        text-decoration: none;
        color: #FFFFFF;

        &:hover {
          color: #FFFFFF;
          opacity: 0.8;
        }
      }
    }
  }

  .copyright {
    margin-top: 54px;
    text-align: center;
    font-family: 'Mulish';
    font-weight: 600;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.7);

    &.smallMargin {
      margin-top: 16px;
    }

    a {
      margin-right: 8px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  
  @include small-break {
    padding-top: 41px;
    padding-bottom: 18px;

    .row {
      flex-direction: column;
      gap: 48px;
      padding: 0;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.big {
        width: 100%;
        max-width: 100%;
      }

      &.small {
        width: 100%;
        max-width: 100%;
      }
    }

    .disclaimers {
      margin-top: 46px;
      text-align: center;
    }

    .icons {
      justify-content: center;
      margin-bottom: 10px;
    }

    .linksWrapper {
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .copyright {
      margin-top: 20px;
    }
  }

  @include mobile-break {
    padding-top: 30px;
    padding-bottom: 60px;

    .row {
      gap: 38px;
    }

    .icons {
      margin-bottom: 42px;
    }

    .linksWrapper {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .copyright {
      margin-top: 42px;
    }
  }
}