@use '@/styles/config/_mixins.scss' as *;

$gap: 20px;

.section {
  margin-top: 40px;
  margin-bottom: 40px;

  .container {
    background-color: #eef0f2;
  }

  .row {
    display: flex;
    gap: 20px;
  }

  .col {
    &.small {
      width: col-width(33.333%, 2, $gap);
    }

    &.big {
      width: col-width(66.666%, 2, $gap);
    }
  }

  .image {
    width: 100%;
    img {
      width: 100%;
      object-position: bottom;
    }
  }

  .content {
    padding: 16px;
    padding-top: 32px;
  }

  .heading {
    margin-bottom: 16px;
    font-size: 48px;
    font-weight: 800;
    line-height: 55px;
    color: #373f41;
  }

  .description {
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
  }

  .btnWrapper {
    display: flex;
    justify-content: center;
  }

  .btn {
    background-color: #89c440;
    border-radius: 34px;
    color: #262830;
  }

  @include small-break {
    .container {
      background-color: transparent;
    }

    .row {
      flex-direction: column;
      align-items: center;
    }

    .col {
      &.small {
        width: 100%;
      }

      &.big {
        width: 100%;
      }
    }

    .heading {
      font-size: 32px;
      line-height: 1.2;
      text-align: center;
    }

    .description {
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
    }

    .image {
      background-color: #eef0f2;
    }
  }
}