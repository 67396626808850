@use '@/styles/config/_mixins.scss' as *;

$gap: 20px;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 16px;

  .container {
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 0 0 18px rgba(0,0,0,.33);
    border-radius: 10px;
    background: url("/images/faq-bg.webp");
  }

  .benefitsRow {
    display: flex;
    gap: $gap;
  }

  .benefit {
    width: col-width(50%, 2, $gap);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .benefitImage {
    max-width: 200px;
  }

  .benefitHeading {
    margin-bottom: 8px;
    font-size: 32px;
    text-align: center;
  }

  .benefitDescription {
    margin-bottom: 16px;
    font-size: 14px;
    text-align: center;
  }

  .content {
    width: 100%;
    max-width: 940px;
    margin: auto;
    padding: 25px 20px 38px;
    padding-bottom: 0;
  }

  .heading {
    margin-bottom: 8px;
    font-size: 32px;
    text-align: center;
  }

  .description {
    margin-bottom: 16px;
    font-size: 14px;
    text-align: center;
  }

  .disclaimer {
    text-align: center;
    font-size: 12px;
    color: #6b6b6b;

    h6 {
      font-size: 12px;
    }

    a {
      color: #373f41;
      text-decoration: underline;
    }
  }

  @include small-break {
    margin-top: 16px;
    margin-bottom: 16px;

    .container {
      max-width: 600px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .benefitsRow {
      flex-direction: column;
    }

    .benefit {
      width: 100%;
    }

    .heading {
      font-size: 22px;
    }

    .benefitHeading {
      font-size: 22px;
    }

    .content {
      padding: 0;
    }
  }
}