@use '@/styles/config/_mixins.scss' as *;

.section {
  padding: 12px 22px;
  background-color: #eef8ff;

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .col {
    &.colTitle {
      flex-shrink: 0;
    }
  }

  .imageWrapper {
    width: 60px;
    height: 60px;
  }

  .title {
    margin: 0;
    padding-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
    color: #2852a1;
    text-decoration: underline;
    text-underline-offset: 20px;
  }

  .date {
    display: flex;
    gap: 5px;
  }

  .dateItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      .dateItemValue {
      }
    }
  }

  .dateItemValue {
    font-size: 30px;
  }

  .dateItemLabel {
    font-size: 12px;
  }

  .colon {
    font-size: 30px;
  }

  @include mobile-header-break {
    .title {
      font-size: 24px;
    }
  }

  @include extra-small-break {
    .col {
      &.colImage {
        display: none;
      }
    }
  }
}
