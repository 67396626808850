@use '@/styles/config/_mixins.scss' as *;

.section {
  padding: 70px 0;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .heading {
    margin: 0;
    font-weight: 600;
    font-size: 42px;
    text-align: center;

    strong {
      display: block;
      text-transform: uppercase;
      color: #8bc340;
    }
  }

  .link {
    background-color: var(--button-background);
    color: var(--button-text-color);
  }

  @include extra-small-break {
    padding: 30px 0;

    &.pillar {
      padding-top: 35px;
      padding-bottom: 29px;

      :global(.container) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.6667;
        letter-spacing: 0.3px;
      }
    }

    .heading {
      font-size: 25px;
    }
  }
}