@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-top: 62px;
  margin-bottom: 62px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.62;
  text-align: center;
  color: var(--text-color);

  &.small {
    .wrapper {
      border-top: 2px solid #1D77B8;
      border-bottom: 2px solid #1D77B8;
    }

    .heading {
      font-size: 1rem;
      line-height: 1.62;
      text-align: center;
      color: #434343;
    }
  }

  &.big {
    .heading {
      font-family: Mulish;
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: .3px;
      text-align: center;
    }

    .description {
      font-family: Mulish;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: .3px;
      text-align: center;
    }
  }

  .wrapper {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  a {
    color: #1E8DE8;
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }
  }

  .container {
    max-width: 1160px;
    background-color: var(--bg-color);
  }

  .description {
    letter-spacing: 0.3px;
    font-size: 16px;
    line-height: 1.43;
    text-align: center;
  }

  @include small-break {
    margin-top: 42px;
    padding-top: 42px;
    padding-bottom: 20px;
  }

  @include extra-small-break {
    &.big {
      .heading {
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 12px;
      }

      .description {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  @include mobile-break {
    margin-top: 32px;
    margin-bottom: 30px;
    margin-left: 14px;
    margin-right: 14px;
    padding-top: 28px;
    padding-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
