@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-top: 50px;
  margin-bottom: 50px;

  .title, h1 {
    margin-bottom: 20px;
    text-align: center;
  }

  @include extra-small-break {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.rte {
  font-size: 16px;
  word-break: break-word;

  ol, ul {
    padding-left: 20px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
    text-align: center;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  img {
    margin: 20px auto;
  }
}

.videoWrapper {
  width: 100%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}