@use '@/styles/config/_mixins.scss' as *;

.section {
  font-family: 'Mulish', sans-serif;

  .container {
    max-width: 1210px;
  }

  .bodyMobile {
    display: none;
  }

  .title {
    margin-bottom: 0;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
  }

  .table {
    display: flex;
    flex-wrap: wrap;
  }

  .tableTitle {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.3px;
    line-height: 1.5;
    color: #000000;
  }

  .col {
    width: 33.333333%;
    min-height: 92px;
    border: 1px solid #c0c4c5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 14px 22px;

    &.colFirstLine {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 68px;
      padding-top: 4px;
      padding-bottom: 4px;
      gap: 12px;

      .tableTitle {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;
      }

      .colText {
        font-weight: 500;
      }

      img {
        height: 31px;
        width: auto;
        flex-shrink: 0;
      }
    }

    &.emptyCol {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #757575;
    }

    &:nth-child(3n-2):not(:nth-child(1)) {
      background-color: #1D77B8;
      color: #ffffff;

      .colText,
      .colTitle {
        color: #ffffff;
      }
    }

    &:nth-child(3n-1):not(:nth-child(2)) {
      background-color: #F7F6F7;
    }
  }

  .slideHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0 15px;

    .tableTitle {
      font-size: 28px;
    }

    img {
      height: 45px;
      width: auto;
    }
  }

  .colTitle {
    margin-bottom: 0;
    color: #2852A1;
    font-size: 28px;
    line-height: 1.25;
    font-weight: 600;
    text-align: center;

    strong {
      font-weight: 700;
      color: #1D77B8;
    }

    & + .colContent {
      justify-content: center;
      text-align: center;

      .colText {
        font-weight: 500;
      }
    }
  }

  .colText {
    margin-bottom: 0;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.3px;
    font-weight: 700;
    line-height: 1.111;

    small {
      font-size: 16px;
    }
  }

  .colContent {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .colContentIconWrapper {
    flex-shrink: 0;

    &.checkmark {
      color: #89C440;
    }

    &.cross {
      color: #E45B5B;
    }
  }

  .sliderWrapper {
    max-width: 676px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .slide {
    background-color: #1D77B8;
    box-shadow: 0 4px 4px 0 #00000040;
    padding-top: 28px;
    padding-bottom: 18px;
  }

  swiper-container {
    --swiper-pagination-color: #8BC443;
    --swiper-pagination-bullet-inactive-color: #8BC443;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-size: 13.32px;
    width: 100%;
    max-width: 100%;

    &:not(:has(:global(.swiper-slide-active))) {
      display: flex;
      padding-bottom: 92px;
      overflow-y: auto;

      swiper-slide {
        width: 100%;
        flex-shrink: 0;
      }
    }

    &::part(bullet-active) {
      outline: 2px solid #1D77B8;
    }

    &::part(container) {
      padding-bottom: 92px;
    }

    &::part(pagination) {
      bottom: 40px;
    }
  }

  @include small-break {
    margin-top: 50px;

    .bodyDesktop {
      display: none;
    }

    .bodyMobile {
      display: block;
    }

    .col {
      width: 100%;
      border-left: none;
      border-right: none;
      padding-top: 28px;
      padding-bottom: 28px;
      align-items: center;
      color: #ffffff;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(3n-1):not(:nth-child(2)) {
        background-color: transparent;
        color: #ffffff;

        .colText,
        .colTitle {
          color: #ffffff;
        }
      }
    }

    .tableTitle {
      margin-bottom: 0;
      font-size: 32px;
      line-height: 1.07;
      color: #FFFFFF;
    }

    .colTitle {
      color: #89C440;
      font-size: 32px;

      strong {
        color: #89C440;
      }
    }

    .colContent {
      width: 310px;
      margin: 0 auto;
    }

    .colText {
      color: #ffffff;

      &.cross {
        text-decoration: line-through;
      }
    }

    .slideHeader {
      img {
        filter: brightness(100);
      }
    }

    .slideList {
      margin-top: 18px;
    }
  }

  @include mobile-break {
    .container {
      padding-left: 42px;
      padding-right: 42px;
    }

    .col {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .sliderWrapper {
      margin-top: 33px;
    }

    .title {
      max-width: 291px;
      margin-left: auto;
      margin-right: auto;
      font-size: 28px;
    }

    .slide {
      padding-top: 38px;
      padding-bottom: 0;
    }

    .slideHeader {
      flex-direction: column;

      .tableTitle {
        font-size: 24px;
      }

      img {
        height: 78px;
        width: auto;
      }
    }

    .slideHeaderIcon {
      img {
        width: 64px;
        height: 70px;
      }
    }

    .slideList {
      margin-top: 28px;
    }

    .colTitle {
      font-size: 24px;
      line-height: 1.1667;
    }

    .tableTitle {
      max-width: 260px;
      font-size: 24px;
    }
  }

  @media (max-width: 375px) {
    .colContent {
      width: auto;
    }
  }
}
