@use '@/styles/config/_mixins.scss' as *;

.section {
  padding-top: 35px;
  padding-bottom: 35px;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
  }

  .col {
    width: 33.333%;
    padding-bottom: 16px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 155px;
  }

  .title {
    min-height: 2.3em;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.15;
    text-align: center;
    color: #1D77B8;
  }

  .description {
    padding-top: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.44;
    text-align: center;
    color: #434343;
  }

  @include small-break {
    padding-top: 38px;
    padding-bottom: 38px;

    .iconWrapper {
      height: 116px;
      margin-bottom: 22px;
    }

    .title {
      font-size: 1.5rem;
      line-height: 1.17;
    }

    .description {
      padding-top: 12px;
      font-size: 1rem;
    }
  }

  @include extra-small-break {
    padding-top: 32px;
    padding-bottom: 32px;

    .row {
      margin: 0;
    }

    .col {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }

    .title {
      padding-left: 10px;
      padding-right: 10px;
    }

    .card {
      width: 100%;
      max-width: 242px;
      margin-left: auto;
      margin-right: auto;
    }

    .iconWrapper {
      height: unset;
      margin-bottom: 30px;
    }

    .description {
      padding-top: 8px;
    }
  }
}