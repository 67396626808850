@use '@/styles/config/mixins' as *;

.section {
  margin-top: 21px;

  .iconsWrapper {
    background: #1D77B8;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .container {
    max-width: 1220px;
  }

  .title {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 19px;
    font-family: Mulish, sans-serif;
    font-weight: 800;
    font-size: 34px;
    line-height: 1.47;
    text-align: center;
    color: #1771AE;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 150px;
      height: 2px;
      background-color: #2EC79D;
      transform: translateX(-50%);
    }
  }

  .description {
    margin-bottom: 56px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    text-align: justify;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .col {
    width: col-width(20%, 5, 17px);
  }

  .item {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 27px;
    padding-bottom: 27px;
    background-color: #FFFFFF;
    border-radius: 5px;
    gap: 21px;
    border: 5px solid transparent;
    transform: scale(1);
    transition: transform 0.3s ease, border-color 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    &.active {
      border-color: #8AC345;
    }
  }

  .imageWrapper {
    width: 98px;
    height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemTitle {
    padding-left: 16px;
    padding-right: 16px;
    font-family: Mulish, sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    color: #1771AE;
  }

  .mobile {
    display: none;
    background-color: #1D77B8;

    :global(.swiper) {
      --swiper-pagination-color: #8BC443;
      --swiper-pagination-bullet-inactive-color: #8BC443;
      --swiper-pagination-bullet-inactive-opacity: 1;
      --swiper-pagination-bullet-size: 13.32px;
      --swiper-navigation-size: 35px;
      --swiper-navigation-sides-offset: 25px;
      --swiper-navigation-color: #1771AE;
      width: 100%;
      max-width: 100%;

      :global(.swiper-pagination-bullet) {
        border: 2px solid #1771AE;
      }

      :global(.swiper-pagination-bullet-active) {
        border: 2px solid #E0F5FF;
      }

      :global(.swiper-pagination) {
        bottom: 50px;
      }

      :global(.swiper-button-prev), :global(.swiper-button-next) {
        color: #FFFFFF;
        top: 36%;
      }

      &:not(:has(:global(.swiper-slide-active))) {
        display: flex;
        overflow-y: auto;

        .swiper-slide {
          width: 100%;
          flex-shrink: 0;
        }
      }
    }
  }

  :global(.swiper-slide) {
    height: auto;
    padding-bottom: 103px;
  }

  .mobileItem {
    height: 100%;
    width: 100%;
    max-width: 253px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    border: 5px solid #8AC345;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 35px;
  }

  .itemsTitle {
    margin-top: 45px;
    margin-bottom: 18px;
    font-family: "Mulish", sans-serif;
    font-size: 26px;
    line-height: 1;
    letter-spacing: 0.3px;
    text-align: center;
    color: #1771AE;
  }

  .swiperContainer {
    width: 100%;
  }

  .accordionsWrapper {
    margin-top: 17px;
    margin-bottom: 57px;

    button[aria-expanded] {
      background-color: #FFFFFF;
    }
  }

  .accordionsTitle {
    margin-bottom: 37px;
    font-weight: 800;
    font-size: 34px;
    line-height: 1.47;
    color: #1D77B8;
    text-align: center;
  }

  @include mid-break {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @include extra-small-break {
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      flex-direction: column;
    }

    .itemsTitle {
      font-weight: 700;
      font-size: 26px;
      line-height: 1;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .imageWrapper {
      background: transparent;
      width: 116px;
      margin: 0 0 16px;
      padding: 0;
      border-radius: 0;

      div {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}