.table {
  width: 100%;
  margin: 20px 0;
  color: #212529;
  border-collapse: collapse;

  th,
  td {
    padding: 32px;
    padding-bottom: 32px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: left;
    font-weight: 400;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    font-weight: 700;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
  }
}