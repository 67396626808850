@use '@/styles/config/_mixins.scss' as *;

$gap: 20px;

.section {
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
  }

  .col {
    width: col-width(50%, 2, $gap);

    &:first-child {
      width: 100%;
    }
  }

  .empty {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 24px;
  }

  @include mobile-break {
    .col {
      width: 100%;
    }
  }
}
