@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-bottom: 10px;
  padding-left: 53px;
  padding-right: 53px;

  .container {
    max-width: 760px;
    padding-top: 20px;
    padding-bottom: 20px;

    .col {
      img {
        width: auto;
        height: 97px;
      }
    }
  }

  .title {
    text-align: center;
    color: #1D77B8;
    margin-bottom: 36px;
    font-size: 30px;
    line-height: 1.06;
    font-weight: 700;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    &.spaceBetween {
      justify-content: space-between;
    }
  }

  .col {
    width: 25%;
    max-width: 235px;
    padding-left: 20px;
    padding-right: 20px;

    img {
      width: 167px;
      height: 167px;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
    }
  }

  @include mid-break {
    .title {
      margin-bottom: 24px;
    }
  }

  @include small-break {
    padding-left: 20px;
    padding-right: 20px;

    .container {
      padding-left: 0;
      padding-right: 0;

      .row {
        align-items: center;
      }

      .col {
        width: 25%;
        max-width: 25%;
        padding: 5px 15px;

        img {
          width: auto;
          height: auto;
        }
      }

      @media (max-width: 370px) {
        .col {
          width: 50%;
          max-width: 50%;
          padding: 15px;
        }
      }
    }

    :global(.container) {
      max-width: 690px;
    }

    .row {
      &.spaceBetween {
        justify-content: center;
      }
    }

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .col {
      max-width: 100%;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
