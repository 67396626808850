@use '@/styles/config/_mixins.scss' as *;

.section {
  position: fixed;
  z-index: 15;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  padding: 33px 50px;
  transform: translateY(100%);
  transition: transform 0.3s ease;

  .content {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &.visible {
    transform: translateY(0);
  }

  &.left {
    .content {
      justify-content: flex-start;
    }
  }

  &.center {
    .content {
      justify-content: center;
    };
  }

  &.right {
    .content {
      justify-content: flex-end;
    }
  }

  .heading {
    font-weight: 700;
    letter-spacing: .3px;
    font-size: 20px;
    line-height: 1;
  }

  .link {
    flex-shrink: 0;
    background-color: var(--button-background);
    color: var(--button-text);
    border-radius: 50px;

    &:only-child {
      min-width: min(318px, 100%);
      padding: 22px;
      border-radius: 8px;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1;
      text-align: center;
    }
  }

  @include extra-small-break {
    &.blog {
      padding-top: 19px;
      padding-bottom: 20px;

      .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .heading {
        margin: 0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.225;
        letter-spacing: 0.3px;
      }

      .link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 9px;
        width: 175px;
        min-height: 45px;
      }
    }
  }

  @include mobile-break {
    padding-left: 20px;
    padding-right: 20px;

    .heading {
      margin-right: auto;
      font-size: 14px;
      line-height: 1.286;
    }

    .link {
      padding: 17px 25px;
      font-size: 14px;
      line-height: 1.286;
    }
  }
}