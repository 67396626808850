@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-bottom: 24px;

  .container {
    border-radius: 28px;
    overflow: hidden;
    border: 2px solid #1D77B8;
  }

  .header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 25px 25px 25px 87px;
    background-color: #1D77B8;
    color: #FFFFFF;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 38px;
      width: 39px;
      height: 100%;
      background-color: #439D7D;
    }

    svg {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    h2.heading {
      margin-top: 0;
      font-weight: 700;
      font-size: 30px;
      line-height: 1;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  .content {
    padding: 40px 38px 77px;

    .list {
      list-style: none;
      margin: 0;
      padding-left: 0;

      .item {
        margin-bottom: 17px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #1D77B8;
          font-weight: 500;
          font-size: 20px;
          line-height: 1.45;
        }
      }
    }
  }

  @include mobile-break {
    margin-bottom: 12px;

    .header {
      padding-left: 60px;

      &:before {
        left: 20px;
        width: 30px;
      }

      h2.heading {
        font-size: 24px;
      }
    }

    .content {
      padding: 20px;

      .list {
        .item {
          margin-bottom: 12px;

          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}