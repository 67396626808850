@use '@/styles/config/_mixins.scss' as *;

$gap: 72px;

.wrapper {
  margin-top: 31px;
  margin-bottom: 28px;

  .container {
    font-family: 'Mulish';
    max-width: 1040px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
  }

  .col {
    width: col-width(33.333%, 3, $gap);
  }

  .featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 100%;
  }

  .featureItemContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .featureItemIcon {
    &.tablet {
      display: none;
    }
  }

  .featureItemTitle {
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    font-weight: 700;
    line-height: 1.5;
    color: #1139A0;
  }

  .featureItemDescription {
    font-size: 16px;
    line-height: 1.375;
    color: #0A0A14;
  }

  .linkWrapper {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }

  .link {
    padding: 20px 33px;
    min-width: 338px;
    text-align: center;
    line-height: 1.1666;
  }

  @include small-break {
    margin-top: 16px;
    margin-bottom: 40px;

    .row {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .col {
      width: 100%;
      max-width: 620px;
    }

    .featureItem {
      flex-direction: row;
      align-items: stretch;
      gap: 24px;
    }

    .featureItemContent {
      gap: 5px;
      align-items: stretch;
    }

    .featureItemTitle {
      font-size: 24px;
      text-align: left;
      line-height: 1;
    }

    .featureItemDescription {
      font-size: 18px;
      line-height: 1.222;
    }

    .featureItemIcon {
      display: none;

      &.tablet {
        display: block;
        flex-shrink: 0;
      }
    }

    .linkWrapper {
      margin-top: 80px;
    }
  }

  @include mobile-break {
    margin-top: 18px;
    margin-bottom: 29px;

    .row {
      gap: 36px;
      align-items: center;
    }

    .col {
      max-width: 272px;
    }

    .featureItem {
      flex-direction: column;
      gap: 18px;
    }

    .featureItemContent {
      align-items: center;
      gap: 20px;
    }

    .featureItemIcon {
      display: block;

      &.tablet {
        display: none;
      }
    }

    .linkWrapper {
      margin-top: 36px;
    }
  }

  @include small-mobile-break {
    .link {
      min-width: auto;
      width: 100%;
    }
  }
}
