@use '@/styles/config/_mixins.scss' as *;

.container {
  width: 100%;

  .title {
    text-align: center;
  }

  .iconsContainer {
    width: 100%;
    max-width: 1039px;
    display: flex;
    justify-content: space-between;
    margin: 21px auto 58px;
    padding-top: 21px;
    padding-bottom: 21px;

    .iconBlock {
      width: 100%;
      max-width: 196px;
      display: flex;
      flex-direction: column;
      gap: 23px;
      align-items: center;
      justify-content: center;

      .iconTitle {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0.3px;
        text-align: center;
      }
    }

    @include small-break {
      max-width: 652px;
      display: flex;
      gap: 25px;
      margin: 45px auto 63px;

      .iconBlock {
        width: 100%;
        gap: 8px;

        .imgWrapper {
          width: 140px;
          height: auto;
        }

        .iconTitle {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    @include extra-small-break {
      max-width: 196px;
      flex-direction: column;
      gap: 19px;
      margin: 13px auto 69px;

      .iconBlock {
        width: 100%;

        .imgWrapper {
          width: 170px;
          height: auto;
        }

        .iconTitle {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}