@use '@/styles/config/_mixins.scss' as *;

.container {
  width: 100%;
  max-width: 1197px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 0px auto 63px;

  &.reversed {
    flex-direction: row-reverse;
  }

  .contentBlock {
    display: flex;
    flex-direction: column;
    width: calc(50% - 5px);
    max-width: 553px;

    .contentTitle {
      background-color: #1D77B8;
      color: #FFFFFF;
      display: flex;
      padding: 15px 96px;
      font-family: Mulish;
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.3px;
      text-align: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 17px;
      justify-content: center;
    }

    .contentDescription {
      font-family: Mulish;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.1px;
      text-align: justify;
      color: #1B1E3F;
    }

    .listWrapper {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .listTitle {
      margin-bottom: 16px;
    }

    .btnWrapper {
      margin-top: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        color: #262830;
        width: 338.52px;
        height: 68px;
        border-radius: 34px;
        font-family: Mulish;
        font-size: 24px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.3px;
        text-align: center;
        background-color: #89C440;
        display: inline-block;
        padding: 20px;
        text-decoration: none;
      }
    }
  }

  .imgWrapper {
    width: calc(50% - 5px);
    max-width: 584.81px;

    img {
      object-fit: cover;
      aspect-ratio: 574 / 388;
      height: auto;
    }
  }

  @include small-break {
    width: 100%;
    max-width: 654px;
    justify-content: center;
    margin: 0px auto 40px;
    gap: 40px;

    .contentBlock {
      width: 100%;
      max-width: 654px;

      .contentTitle {
        justify-content: center;
      }

      .contentDescription {
        max-width: 557px;
        display: block;
        margin: auto;
        text-align: center;
      }

      .btnWrapper {
        margin-top: 20px;
      }
    }

    .imgWrapper {
      width: 100%;
    }
  }

  @include extra-small-break {
    width: 100%;
    margin: 0px auto 32px;
    gap: 18px;

    .contentBlock {
      .contentTitle {
        font-size: 22px;
        line-height: 30px;
        padding: 20px 33px;

        .iconWrapper {
          width: 31px;
          height: 37px;
        }
      }

      .contentDescription {
        display: block;
        margin: auto;
        font-size: 16px;
        line-height: 22px;
        height: auto;
      }

      .listTitle {
        text-align: center;
      }

      .btn {
        width: 279px;
        height: 56px;
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.3px;
        margin: 18px auto 0px;
      }
    }

    .imgWrapper {
      width: 100%;
    }
  }
}