@use '@/styles/config/_mixins.scss' as *;

.section {
  padding-top: 67px;
  padding-bottom: 27px;

  .row {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .col {
    &.big {
      flex: 1 1 auto;

      &:only-child {
        width: 100%;
      }
    }

    &.small {
      width: 359px;
      flex-shrink: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 42px;
  }

  .heading {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 28px;
    font-family: Montserrat;
    font-weight: 800;
    font-size: 2.125rem;
    line-height: 1.47;
    color: #1d77b8;

    strong {
      color: #8ac345;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -2px;
      width: 157px;
      height: 2px;
      background-color: #1d77b8;
    }
  }

  .description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.2;
    color: #434343;
  }

  .image {
    border-radius: 50%;
  }

  @include small-break {
    padding-top: 20px;
    padding-bottom: 20px;

    .row {
      flex-direction: column-reverse;
    }

    .col {
      &.big {
        width: 100%;
      }

      &.small {
        width: 100%;
      }
    }

    .image {
      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include mid-break {
    .heading {
      font-size: 1.6rem;
    }
  }
}